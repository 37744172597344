import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { CustomField } from '../../admin/customFields/entities/customFields.entity';
import {
  AssignedCustomField,
  CustomFieldValue,
} from '../SimInventoryApi/simInventoryApi.interface';
import { getAssignedFields } from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';

export const useSimCustomFields = (customFields?: CustomFieldValue[], queryKey?: string) => {
  const ability = useAbility();
  const customFieldsEnabled = ability.can(Actions.read, Subjects.customFields);
  const { data: customFieldsData } = useQuery<CustomField[]>({
    queryKey: [queryKey, customFieldsEnabled],
    enabled: false, //It will use previous fetched custom fields
    initialData: customFieldsEnabled ? undefined : [],
  });

  const clearCustomFields = () => {
    setAssignedCustomFields(getAssignedFields(customFields || [], customFieldsData || []));
  };

  const [assignedCustomFields, setAssignedCustomFields] = useState<AssignedCustomField[]>([]);

  const originalCustomFields = getAssignedFields(customFields || [], customFieldsData || []);

  useEffect(() => {
    if (customFieldsData) {
      setAssignedCustomFields(getAssignedFields(customFields || [], customFieldsData || []));
    }
  }, [customFieldsData, customFields]);

  return {
    customFieldsData,
    assignedCustomFields,
    originalCustomFields,
    setAssignedCustomFields,
    clearCustomFields,
  };
};
