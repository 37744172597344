import { useAtom, useSetAtom } from 'jotai';
import {
  chosenIdTypeFilterAtom,
  iccidRangeFilterAtom,
  searchTextFilterAtom,
} from 'atoms/filters/sim';
import { useCallback, useEffect, useState } from 'react';
import { SimIdSearchInput } from 'simInventory/Components/Inputs/SimIdSearchInput';
import { useDebounce } from 'usehooks-ts';

export const IdSearchInput = () => {
  const [chosenIdType, setChosenIdType] = useAtom(chosenIdTypeFilterAtom);
  const [searchText, setSearchText] = useAtom(searchTextFilterAtom);
  const setIccidRange = useSetAtom(iccidRangeFilterAtom);

  const [searchValue, setSearchValue] = useState(searchText);

  const onChange = useCallback((value: string) => {
    setSearchValue(value);
    if (value !== '') {
      setIccidRange({ iccidFrom: '', iccidTo: '' });
    }
  }, []);

  const debouncedSearchText = useDebounce(searchValue || '', 500);
  useEffect(() => {
    const minLengthText = debouncedSearchText.length < 3 ? '' : debouncedSearchText;
    setSearchText(minLengthText);
  }, [debouncedSearchText]);

  useEffect(() => {
    setSearchValue(searchText);
  }, [searchText]);

  return (
    <SimIdSearchInput
      chosenId={chosenIdType}
      onChosenIdChange={setChosenIdType}
      search={searchValue}
      onSearchChange={onChange}
    />
  );
};
