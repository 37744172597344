import { useQuery } from '@tanstack/react-query';
import { customFieldsKey, fetchCustomFields } from 'admin/customFields/api/customFieldsApi';
import { CustomField } from 'admin/customFields/entities/customFields.entity';
import { customFieldsFilterAtom } from 'atoms/filters/sim-products';
import { useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';

export const useCustomFieldsFiltersInitialization = () => {
  const ability = useAbility();
  const customFieldsMigrated = useHasFeatureFlag('CustomFieldsMigratedToProductsView');
  const customFieldsEnabled = ability.can(Actions.read, Subjects.customFields);
  const { isFetched, data } = useQuery({
    queryKey: [...customFieldsKey, customFieldsEnabled],
    queryFn: async () => {
      const fields = await fetchCustomFields(customFieldsMigrated);
      return fields.filter((f: CustomField) => f.enabled);
    },
    gcTime: 0,
  });

  const setCustomFieldsFilters = useSetAtom(customFieldsFilterAtom);

  useEffect(() => {
    if (isFetched && data?.length && data?.length > 0) {
      setCustomFieldsFilters((currentFilters: any) => {
        const newCustomFilters = data.reduce((filters, customFields) => {
          let currentValue = currentFilters[customFields.id.toString()];
          if (!currentValue) {
            currentValue = customFields.dataType === 'Dropdown' ? [] : '';
          }

          return {
            ...filters,
            [customFields.id.toString()]: currentValue,
          };
        }, {} as Record<string, string | string[]>);

        return newCustomFilters;
      });
    }
  }, [isFetched, data]);
};
