import React, { useCallback } from 'react';
import { useAtom, useAtomValue } from 'jotai';
import {
  pageFilterAtom,
  rowsPerPageFilterAtom,
  simProductsFiltersAtom,
  simProductsSortingAtomWithStorage,
} from 'atoms/filters/sim-products';
import { Sorting } from 'common/hooks/useSorting';
import { TableLayoutWithPaginationWithoutScrollArea } from 'common/components/Table/TableLayoutWithPaginationWithoutScrollArea';
import { NoDataMessage } from 'common/components/Table/NoDataMessage';
import { useSimProducts } from '../hooks/useSimProducts';
import { ConfigurableTable } from './ConfigurableTable/TableBase';
import { ActivationDialog } from '../Inventory/Actions/ActivationDialog';
import { DeactivationDialog } from '../Inventory/Actions/DeactivationDialog';
import { Action } from 'common/components/ConfigurableTable/models/configurableTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'Routes.constants';
import { ACTIVATION_ACTIONS } from 'rSimInventory/models/RSimProfile.model';
import { SimProductsEditDialog } from './EditDialog/SimProductsEditDialog';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';

export const SimProductsTable = React.memo(() => {
  const filters = useAtomValue(simProductsFiltersAtom);
  const [rowsPerPage, setRowsPerPage] = useAtom(rowsPerPageFilterAtom);
  const [page, setPage] = useAtom(pageFilterAtom);

  const [sorting, setSorting] = useAtom(simProductsSortingAtomWithStorage);

  const {
    simProducts,
    isRefetching,
    pagination: { totalNumberOfItems },
    updateTagsForSim,
  } = useSimProducts(filters, sorting, rowsPerPage, page);

  const onSortModelChange = useCallback((columnName: string, sort: Sorting) => {
    //temporary solution
    setSorting({
      columnName,
      //@ts-ignore
      sort: sort,
    });
  }, []);

  const ability = useAbility();

  const tagsEnabled =
    useHasFeatureFlag('TagsMigratedToProductsView') && ability.can(Actions.edit, Subjects.tags);
  const customFieldsEnabled =
    useHasFeatureFlag('CustomFieldsMigratedToProductsView') &&
    ability.can(Actions.edit, Subjects.customFields);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const actions: Action[] = [
    {
      id: 'viewDetails',
      label: 'View Details',
      type: 'custom',
      actionCallback: (data: any) => {
        navigate(`${Routes.productsSim}/${data.iccid}`, {
          state: {
            previousPath: `${pathname}${search}`,
          },
        });
      },
    },
    {
      id: 'activation',
      label: 'Activate',
      type: 'dialog',
      Component: ActivationDialog,
      enabled: (data) => {
        return data.activationAction === ACTIVATION_ACTIONS.ACTIVATE;
      },
    },
    {
      id: 'deactivation',
      label: 'Deactivate',
      type: 'dialog',
      Component: DeactivationDialog,
      enabled: (data) => {
        return data.activationAction === ACTIVATION_ACTIONS.DEACTIVATE;
      },
    },
    {
      id: 'edit',
      label: 'Edit',
      type: 'dialog',
      Component: SimProductsEditDialog,
      slotProps: {
        onTagsUpdate: updateTagsForSim,
        queryKey: 'SimProductsConfigurable',
      },
      enabled: () => tagsEnabled || customFieldsEnabled,
    },
  ];

  return (
    <TableLayoutWithPaginationWithoutScrollArea
      noItemsMessage={<NoDataMessage searchText={''} prevSearchText={''} />}
      fetching={isRefetching}
      page={page}
      onPageChange={setPage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={(page) => setRowsPerPage(page)}
      totalNumberOfItems={totalNumberOfItems}
      table={
        <ConfigurableTable
          items={simProducts?.items || []}
          actions={actions}
          sort={sorting}
          onSortChange={onSortModelChange}
        />
      }
    />
  );
});
