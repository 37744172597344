import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { subscriptionStatusFilterAtom } from 'atoms/filters/sim';
import { Chip } from 'common/components/Chips/Chip';
import { MultiSelect } from 'common/components/Inputs/MultiSelect/MultiSelect';

import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { SubscriptionStatusFilter } from 'simInventory/models/SimProfile.model';
import {
  SUBSCRIPTION_STATUS,
  SUBSCRIPTION_STATUS_VALUE_LABEL_MAP,
} from 'simInventory/SimInventoryApi/simInventoryApi.constants';

export const MNOStatusInput = () => {
  const { t } = useTranslation();
  const [subscriptionStatus, setSubscriptionStatus] = useAtom(subscriptionStatusFilterAtom);

  const MNOStatusOptions = [
    {
      label: SUBSCRIPTION_STATUS_VALUE_LABEL_MAP[SUBSCRIPTION_STATUS.ACTIVE],
      value: SUBSCRIPTION_STATUS.ACTIVE,
    },
    {
      label: SUBSCRIPTION_STATUS_VALUE_LABEL_MAP[SUBSCRIPTION_STATUS.ACTIVEREADY],
      value: SUBSCRIPTION_STATUS.ACTIVEREADY,
    },
    {
      label: SUBSCRIPTION_STATUS_VALUE_LABEL_MAP[SUBSCRIPTION_STATUS.DEACTIVATED],
      value: SUBSCRIPTION_STATUS.DEACTIVATED,
    },
    {
      label: SUBSCRIPTION_STATUS_VALUE_LABEL_MAP[SUBSCRIPTION_STATUS.RETIRED],
      value: SUBSCRIPTION_STATUS.RETIRED,
    },
    {
      label: SUBSCRIPTION_STATUS_VALUE_LABEL_MAP[SUBSCRIPTION_STATUS.TESTREADY],
      value: SUBSCRIPTION_STATUS.TESTREADY,
    },
    {
      label: SUBSCRIPTION_STATUS_VALUE_LABEL_MAP[SUBSCRIPTION_STATUS.UNRECOGNIZED],
      value: SUBSCRIPTION_STATUS.UNRECOGNIZED,
    },
  ];
  return (
    <MultiSelect<SubscriptionStatusFilter>
      sx={{ width: '100%', height: 'auto' }}
      data-testid={'mno-status-multiselect'}
      name="mnoStatus"
      value={subscriptionStatus}
      onChange={(value) => {
        setSubscriptionStatus(value);
      }}
      showSelectAll={true}
      options={MNOStatusOptions}
      //@ts-ignore
      renderValue={(selected) => {
        if (selected.length === 0) {
          return (
            <Typography variant="text1" color="text.secondary">
              {t('simInventory.mnoFilterStatusPlaceholder')}
            </Typography>
          );
        }

        return (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 1.5,
            }}
          >
            {selected.map((value: SubscriptionStatusFilter) => (
              <span
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              >
                <Chip
                  label={SUBSCRIPTION_STATUS_VALUE_LABEL_MAP[value]}
                  variant="filled"
                  onDelete={(event) => {
                    setSubscriptionStatus((curentStatuses) =>
                      curentStatuses.filter((status) => status !== value),
                    );
                  }}
                />
              </span>
            ))}
          </Box>
        );
      }}
    />
  );
};
