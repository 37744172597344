import { uppercaseFirstLetter } from 'common/utils/stringUtil';
import { isEmpty } from 'lodash';
import {
  NUMERIC_SIM_PROFILE_IDS,
  SESSION_STATUS,
  SESSION_STATUS_NUMERIC,
  SimInventoryExtededFilterBody,
  SimInventoryExtededFilterBodyIdsProps,
  SimInventorySearchParamsV2,
  SimInventorySort,
} from 'simInventory/models/SimProfile.model';

export const prepareSimInventorySearchBody = (
  searchParams: SimInventorySearchParamsV2,
  sort: SimInventorySort | null = null,
) => {
  const body: SimInventoryExtededFilterBody = {};

  if (searchParams.searchText.length === 1) {
    const id = searchParams.chosenIdType.toLowerCase() as SimInventoryExtededFilterBodyIdsProps;
    body[id] = searchParams.searchText[0];
  } else if (searchParams.searchText.length > 0 && searchParams.searchText[0]) {
    body['simProfileIds'] = {
      type: NUMERIC_SIM_PROFILE_IDS[searchParams.chosenIdType],
      ids: searchParams.searchText,
    };
  }

  if (searchParams.connectionId) {
    body['connectionId'] = searchParams.connectionId;
  }

  if (searchParams.orderNumber) {
    body['orderNumber'] = searchParams.orderNumber;
  }

  if (searchParams.sessionStatus !== SESSION_STATUS.UNKNOWN) {
    body['sessionStatus'] = [
      searchParams.sessionStatus === SESSION_STATUS.IN_SESSION
        ? SESSION_STATUS_NUMERIC.IN_SESSION
        : SESSION_STATUS_NUMERIC.DISCONNECTED,
    ];
  }

  if (searchParams.accounts && searchParams.accounts.length > 0) {
    const accounts = searchParams.accounts.map((account) => parseInt(account));
    body.accountRef = accounts;
  }

  if (searchParams.mobileNetworkOperator && searchParams.mobileNetworkOperator.length > 0) {
    body.cciis = searchParams.mobileNetworkOperator;
  }

  if (searchParams.tags && searchParams.tags.length > 0) {
    body.tags = searchParams.tags;
  }

  if (sort) {
    body.order = `${uppercaseFirstLetter(sort.columnName)}:${sort.sort?.toUpperCase()}`;
  }

  if (searchParams.iccidFrom || searchParams.iccidTo) {
    body.simProfileIdsRange = {
      type: 0,
      idsFrom: searchParams.iccidFrom,
      idsTo: searchParams.iccidTo,
    };
  }

  if (searchParams.customFields) {
    const customFields = Object.entries(searchParams.customFields)
      .filter(([, value]) => {
        if (Array.isArray(value) && value.length <= 0) {
          return false;
        }

        return !!value;
      })
      .map(([id, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          return { id, selectionOptionIds: value };
        }
        return { id, textValue: value };
      });
    //@ts-ignore
    body.customFieldSearch = customFields;
  }

  if (!isEmpty(searchParams.subscriptionStatus)) {
    body.subscriptionStatus = searchParams.subscriptionStatus;
  }

  return body;
};

export const preparePaginatedSimInventorySearchBody = (
  page: number,
  entryPerPage: number,
  searchParams: SimInventorySearchParamsV2,
  sort: SimInventorySort | null = null,
) => {
  const body = prepareSimInventorySearchBody(searchParams, sort);
  body.pageNumber = page + 1;
  body.pageSize = entryPerPage;

  return body;
};
