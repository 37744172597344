import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AutocompleteSelect } from 'common/components/Inputs/AutocompleteSelect/AutocompleteSelect';
import React from 'react';
import { EditDialog } from 'common/components/Dialog/EditDialog';
import { useTagsAutocompleteSelect } from 'tags/hooks/useTagsAutocompleteSelect';
import Grid from '@mui/material/Grid2';
import { styled } from '@mui/material';
import { useRefetchQuery } from 'common/useRefetchQuery';
import { Actions, Subjects } from 'permissions/ability';
import { useAbility } from 'permissions/hooks/useAbility';
import { useSimDetails } from 'simInventory/hooks/useSimDetails';
import { useSimCustomFields } from 'simInventory/hooks/useSimCustomFields';
import { useCustomFields } from 'admin/customFields/hooks/useCustomFields';
import { mapAssignedCustomFieldsToCustomField } from 'common/components/Filters/CustomFieldsFilter/customFields.utils';
import { useHasFeatureFlag } from 'featureFlags/useHasFeatureFlag';
import { CustomFieldsEdit } from './components/CustomFieldsEdit';
import { customFieldsKey } from 'admin/customFields/api/customFieldsApi';

export type SimProductsEditDialogProps = {
  open: boolean;
  onClose: () => void;
  data: any;
  slotProps: {
    onTagsUpdate?: (iccid: string, tags: any) => void;
    queryKey?: string;
  };
};

const GridLabelStyled = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
});

export const SimProductsEditDialog = React.memo(
  ({ open, data, onClose, slotProps: { onTagsUpdate, queryKey } }: SimProductsEditDialogProps) => {
    const ability = useAbility();

    const {
      isLoading,
      tags,
      assignedSimTags,

      addNewTags,
      selectTags,
      save: saveTags,
      refresh: refreshTags,

      changed,
      setChanges,
      searchValue,
      setSearchValue,
      errors,
      validateTagLength,
    } = useTagsAutocompleteSelect({
      id: data?.iccid,
      onTagsUpdate,
      enabled: ability.can(Actions.edit, Subjects.tags),
    });

    const { data: simData, refetch: refetchSimData } = useSimDetails(data?.iccid!);

    const iccid = data?.iccid!;
    const {
      customFieldsData,
      assignedCustomFields,
      originalCustomFields,
      clearCustomFields,
      setAssignedCustomFields,
    } = useSimCustomFields(simData?.customFields, customFieldsKey[0]);

    const { editSimCustomFields } = useCustomFields();

    const refetchByQuery = useRefetchQuery([queryKey || '']);

    const refetch = () => {
      if (queryKey) {
        refetchByQuery();
      }
      refetchSimData();
    };

    const tagsEnabled =
      useHasFeatureFlag('TagsMigratedToProductsView') && ability.can(Actions.edit, Subjects.tags);
    const customFieldsEnabled =
      useHasFeatureFlag('CustomFieldsMigratedToProductsView') &&
      ability.can(Actions.edit, Subjects.customFields);

    return (
      <EditDialog
        isLoading={isLoading}
        subtitle={`SIM ${data?.iccid}`}
        unsavedChanges={changed}
        onApplyChangesClick={async () => {
          if (customFieldsEnabled) {
            await editSimCustomFields(
              {
                iccid,
                customFields: mapAssignedCustomFieldsToCustomField(
                  assignedCustomFields,
                  customFieldsData,
                  originalCustomFields,
                ),
              },
              {
                onSuccess: () => {
                  refetch();
                },
              },
            );
          }

          if (tagsEnabled) {
            await saveTags();
            refetch();
          }

          onClose();
        }}
        onCancelClick={onClose}
        onClearChangesClick={() => {
          refreshTags();
          clearCustomFields();
          setChanges(false);
        }}
        open={open}
      >
        <Stack
          direction="row"
          gap={12}
          alignItems="center"
          sx={{ borderLeft: (theme) => `1px solid ${theme.palette.text.tertiary}` }}
        >
          <Grid container columnSpacing={10} rowSpacing={4} flexGrow={1}>
            {tagsEnabled && (
              <>
                <GridLabelStyled size={4}>
                  <Typography variant="text2" color="text.secondary" sx={{ ml: 4 }}>
                    Tags
                  </Typography>
                </GridLabelStyled>
                <Grid size={8}>
                  <AutocompleteSelect
                    errors={errors}
                    values={assignedSimTags!}
                    options={tags || []}
                    onChange={(values) => {
                      if (values.length <= 20) {
                        selectTags(values.map((value) => value.id));
                        setChanges(true);
                      }
                    }}
                    onAdd={(newValues) => {
                      addNewTags(newValues);
                    }}
                    inputValue={searchValue}
                    onTextInputChange={(value) => {
                      if (validateTagLength(value)) {
                        setSearchValue(value);
                      }
                    }}
                    testId="tags-multiselect"
                  />
                </Grid>
              </>
            )}
            {customFieldsEnabled && (
              <CustomFieldsEdit
                customFieldsData={customFieldsData}
                setChanges={setChanges}
                assignedCustomFields={assignedCustomFields}
                setAssignedCustomFields={setAssignedCustomFields}
              />
            )}
          </Grid>
        </Stack>
      </EditDialog>
    );
  },
);
