import { useAtom, WritableAtom } from 'jotai';
import React, { SetStateAction } from 'react';
import { SubscriptionStatusFilter } from 'simInventory/models/SimProfile.model';
import { FilterChip } from './FilterChip';
import { SUBSCRIPTION_STATUS_VALUE_LABEL_MAP } from 'simInventory/SimInventoryApi/simInventoryApi.constants';
import { useTranslation } from 'react-i18next';

type MnosChipsProps = {
  subscriptionStatusAtom: WritableAtom<
    SubscriptionStatusFilter[],
    [SetStateAction<SubscriptionStatusFilter[]>],
    void
  >;
};

export const SubscriptionStatusChips = ({ subscriptionStatusAtom }: MnosChipsProps) => {
  const { t } = useTranslation();
  const [subscriptionStatuses, setSubscriptionStatus] = useAtom(subscriptionStatusAtom);

  const onClear = (status: SubscriptionStatusFilter) => {
    setSubscriptionStatus((currentSubscriptionStatus) =>
      currentSubscriptionStatus.filter((subscriptionStatus) => subscriptionStatus !== status),
    );
  };

  if (subscriptionStatuses.length === 0) {
    return null;
  }

  return (
    <>
      {subscriptionStatuses.map((status) => {
        return (
          <React.Fragment key={status}>
            <FilterChip
              text={`${t('simInventory.mnoStatus')}: ${
                SUBSCRIPTION_STATUS_VALUE_LABEL_MAP[status]
              }`}
              onClose={() => {
                onClear(status);
              }}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};
